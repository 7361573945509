import Menu from '../Menu';
import Video from '../Video';

const Header = () => {
	return (
		<>
			<Menu />
			<Video />
		</>
	);
};

export default Header;
