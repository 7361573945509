import React from 'react';
import map from '../../assets/map.png';

const ContactMap = () => {
	return (
		<section className="contacts3 map1 cid-swmMNYhh4p" id="contacts3-8">
			<div className="mbr-overlay" style={{ opacity: 0.6, backgroundColor: '#fafafa' }}></div>
			<div className="container">
				<div className="mbr-section-head">
					<h3 id="contact" className="mbr-section-title mbr-fonts-style align-center mb-0 display-2">
						<strong>Contatos</strong>
					</h3>
				</div>
				<div className="row justify-content-center mt-4">
					<div className="card col-12 col-md-6">
						<div className="card-wrapper">
							<div className="image-wrapper">
								<span className="mbr-iconfont mbri-mobile"></span>
							</div>
							<div className="text-wrapper">
								<h6 className="card-title mbr-fonts-style mb-1 display-5">
									<strong>Telefone</strong>
								</h6>
								<p className="mbr-text mbr-fonts-style display-7">(33) 3271-4427</p>
							</div>
						</div>
						<div className="card-wrapper">
							<div className="image-wrapper">
								<span className="mbr-iconfont mbri-letter"></span>
							</div>
							<div className="text-wrapper">
								<h6 className="card-title mbr-fonts-style mb-1 display-5">
									<strong>Email</strong>
								</h6>
								<p className="mbr-text mbr-fonts-style display-7">
									comercial@softernet.com.br&nbsp;
									<br />
								</p>
							</div>
						</div>
					</div>
					<div className="map-wrapper col-12 col-md-6">
						<div className="google-map">
							<img src={map} alt="Localização" />
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default ContactMap;
