import '../src/assets/fonts/fonts.css';
import '../src/styles/global.css';

import Home from './pages/Home';

const App = () => {
	return (
		<div>
			<Home />
		</div>
	);
};

export default App;
