import SocialMedia from '../SocialMedia';
import ContactMap from '../ContactMap';

const Main = () => {
	return (
		<>
			<section className="cid-swmMAiizQQ" id="features1-4">
				<div className="container">
					<div className="row"></div>
					<div className="row">
						<div className="card col-12 col-md-6 col-lg-3">
							<div className="card-wrapper">
								<div className="card-box align-center">
									<div className="iconfont-wrapper">
										<span className="mbr-iconfont mobi-mbri-code mobi-mbri"></span>
									</div>
									<h5 className="card-title mbr-fonts-style display-7">
										<strong>Desenvolvimento de Softwares</strong>
									</h5>
									<p className="card-text mbr-fonts-style display-7">
										Possuímos uma equipe com profissionais formados em diversas áreas da Tecnologia da Informação. Focados em desenvolver
										aplicativos de alta demanda e resultados.
									</p>
								</div>
							</div>
						</div>
						<div className="card col-12 col-md-6 col-lg-3">
							<div className="card-wrapper">
								<div className="card-box align-center">
									<div className="iconfont-wrapper">
										<span className="mbr-iconfont mbrib-desktop"></span>
									</div>
									<h5 className="card-title mbr-fonts-style display-7">
										<strong>Equipamentos de Informática</strong>
									</h5>
									<p className="card-text mbr-fonts-style display-7">
										Trabalhamos com as marcas líderes de mercado com uma equipe treinada e capacitada a exercer com excelência em
										atendimento, priorizando as necessidades do cliente.
									</p>
								</div>
							</div>
						</div>
						<div className="card col-12 col-md-6 col-lg-3">
							<div className="card-wrapper">
								<div className="card-box align-center">
									<div className="iconfont-wrapper">
										<span className="mbr-iconfont mbrib-database"></span>
									</div>
									<h5 className="card-title mbr-fonts-style display-7">
										<strong>Softwares para Automação Comercial</strong>
									</h5>
									<p className="card-text mbr-fonts-style display-7">
										Conheça o SGI, nosso Sistema de Gestão Comercial. Ligue e agende hoje mesmo uma demonstração com nossos técnicos!
									</p>
								</div>
							</div>
						</div>
						<div className="card col-12 col-md-6 col-lg-3">
							<div className="card-wrapper">
								<div className="card-box align-center">
									<div className="iconfont-wrapper">
										<span className="mbr-iconfont mbrib-help"></span>
									</div>
									<h5 className="card-title mbr-fonts-style display-7">
										<strong>Equipe de Suporte Especializada</strong>
									</h5>
									<p className="card-text mbr-fonts-style display-7">
										Contamos com dezenas de colaboradores especializados no suporte de sistemas e equipamentos, garantimos agilidade e
										rapidez em nosso atendimento.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="image2 cid-swmMIC0aea" id="business">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-12 col-lg-6">
							<div className="image-wrapper">
								<img src="/assets/images/logo-1076x605.jpg" alt="Mobirise" />
							</div>
						</div>
						<div className="col-12 col-lg">
							<div className="text-wrapper">
								<h3 className="mbr-section-title mbr-fonts-style mb-3 display-5">
									<strong>A Empresa</strong>
								</h3>
								<p className="mbr-text mbr-fonts-style display-7">
									A Softer Informática surgiu em novembro de 1993 atuando apenas no desenvolvimento de sistemas e automação comercial. Somos
									desenvolvedores do Software SGI, homologado para diversos segmentos do mercado, onde conseguimos adequá-lo conforme a
									necessidade da sua empresa e do fisco.
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="content7 cid-swnqTgvyxL" id="content7-n">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-12 col-md-11">
							<blockquote>
								<h5 className="mbr-section-title mbr-fonts-style mb-2 display-7">
									<strong>Nossa Missão e Visão</strong>
								</h5>
								<p className="mbr-text mbr-fonts-style display-4">
									Atender as necessidades de informática dos nossos clientes, com qualidade e compromisso, visando o desenvolvimento das
									pessoas e do negócio. Ser a melhor empresa de informática da região, para se relacionar e trabalhar.
								</p>
							</blockquote>
						</div>
					</div>
				</div>
			</section>

			<section className="gallery2 cid-swnqWinc6C" id="services">
				<div className="container">
					<div className="mbr-section-head">
						<h4 className="mbr-section-title mbr-fonts-style align-center mb-0 display-2">
							<strong>Serviços</strong>
						</h4>
					</div>
					<div className="row mt-4">
						<div className="item features-image сol-12 col-md-6 col-lg-6">
							<div className="item-wrapper">
								<div className="item-img">
									<img src="/assets/images/MONTAGEM.jpg" alt="" />
								</div>
								<div className="item-content">
									<h5 className="item-title mbr-fonts-style display-5">Manutenção de Computadores e Notebooks</h5>

									<p className="mbr-text mbr-fonts-style mt-3 display-7">
										Líder na região no serviço de montagem e manutenção de computadores e notebooks. Os melhores preços e as melhores peças
										dos principais fabricantes!
									</p>
								</div>
							</div>
						</div>
						<div className="item features-image сol-12 col-md-6 col-lg-6">
							<div className="item-wrapper">
								<div className="item-img">
									<img src="/assets/images/DESENVOLVIMENTO.jpg" alt="" />
								</div>
								<div className="item-content">
									<h5 className="item-title mbr-fonts-style display-5">Sistema de Gestão Comercial (SGI)</h5>

									<p className="mbr-text mbr-fonts-style mt-3 display-7">
										Desenvolvimento de Softwares próprios para automação comercial. Atendemos uma diversidade de negócios com softwares
										atualizados!
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<ContactMap />
			<SocialMedia />
		</>
	);
};

export default Main;
