import React from 'react';

const SocialMedia = () => {
	return (
		<section className="share3 cid-swnpYxjHsd" id="share3-l">
			<div className="mbr-overlay" style={{ opacity: 0, backgroundColor: '#fafafa' }}></div>

			<div className="container">
				<div className="media-container-row">
					<div className="col-12">
						<h3 className="mbr-section-title align-center mb-3 mbr-fonts-style display-2">
							<strong>Siga-nos!</strong>
						</h3>
						<div className="social-list align-center">
							<a className="iconfont-wrapper bg-facebook m-2 " href="https://www.facebook.com/softer.informatica" target="_blank">
								<span className="socicon-facebook socicon"></span>
							</a>

							<a className="iconfont-wrapper bg-instagram m-2" href="https://www.instagram.com/softerinformatica/" target="_blank">
								<span className="socicon-instagram socicon"></span>
							</a>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default SocialMedia;
