import './styles.css';

const Video = () => {
	return (
		<section
			className='videoContainer'
			style={{
				backgroundImage: `url("/assets/images/background3.jpg")`,
				backgroundPosition: 'center',
				backgroundSize: 'cover',
				backgroundRepeat: 'no-repeat',
				// backgroundSize: '100%',
				height: 400,
			}}
		>
			{/* <video autoPlay loop muted>
				<source src='/assets/mixkit-information-being-downloaded-from-the-cloud-31544-medium.mp4' type='video/mp4' />
			</video> */}
			<div className='videoText'>
				<div className='mb-5 mt-5 d-flex justify-content-center'>
					<img src='assets/images/logo-1-1646x477.png' alt='Softernet Logo' />
				</div>
				<p className='mbr-text mbr-fonts-style'>Tecnologia com Qualidade</p>
			</div>
		</section>
	);
};

export default Video;
