const Footer = () => {
	return (
		<>
			<section className="footer7 cid-swnpVxlmRZ" id="footer7-k">
				<div className="container">
					<div className="media-container-row align-center mbr-white">
						<div className="col-12">
							<p className="mbr-text mb-0 mbr-fonts-style display-7">Copyright © 2021 SOFTER INFORMÁTICA. Todos os direitos reservados.</p>
						</div>
					</div>
				</div>
			</section>

			<section style={{ backgroundColor: '#232323', color: '#232323', fontSize: 1, padding: 0, alignItems: 'center', display: 'internal' }}>
				<a href="https://softernet.com.br/" style={{ flex: 1, height: '3rem', paddingLeft: '1rem' }}></a>
				<p style={{ flex: 0, margin: 0, paddingRight: '1rem' }}>
					Design by Softernet
					<a href="https://mobirise.site/z" style={{ color: '#232323' }}>
						.
					</a>
				</p>
			</section>
		</>
	);
};

export default Footer;
