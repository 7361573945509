import React from 'react';

const Menu = () => {
	return (
		<section className="menu menu3 cid-swnnfRN3KL" id="menu3-i">
			<nav className="navbar navbar-dropdown navbar-fixed-top navbar-expand-lg">
				<div className="container">
					<div className="navbar-brand">
						<span className="navbar-logo">
							<a href="#top">
								<img src="assets/images/logo-2-329x95.png" alt="Mobirise" style={{ height: '3rem' }} />
							</a>
						</span>
					</div>
					<button
						className="navbar-toggler"
						type="button"
						data-toggle="collapse"
						data-target="#navbarSupportedContent"
						aria-controls="navbarNavAltMarkup"
						aria-expanded="false"
						aria-label="Toggle navigation"
					>
						<div className="hamburger">
							<span></span>
							<span></span>
							<span></span>
							<span></span>
						</div>
					</button>
					<div className="collapse navbar-collapse" id="navbarSupportedContent">
						<ul className="navbar-nav nav-dropdown nav-right" data-app-modern-menu="true">
							<li className="nav-item hidden">
								<a className="nav-link link text-primary display-4" href="index.html#top">
									<span className="mbri-shopping-cart mbr-iconfont mbr-iconfont-btn"></span>Loja
								</a>
							</li>
							<li className="nav-item">
								<a className="nav-link link text-primary display-4" href="#business">
									A Empresa
								</a>
							</li>
							<li className="nav-item">
								<a className="nav-link link text-primary display-4" href="#services">
									Serviços
								</a>
							</li>
							<li className="nav-item">
								<a className="nav-link link text-primary display-4" href="#contact">
									Contato
								</a>
							</li>
						</ul>
						<div className="icons-menu">
							<a className="iconfont-wrapper" href="https://www.facebook.com/softer.informatica" target="_blank">
								<span className="p-2 mbr-iconfont socicon-facebook socicon" style={{ color: '#104db7', fill: '#104db7' }}></span>
							</a>
							<a className="iconfont-wrapper" href="https://api.whatsapp.com/send?phone=553332714427" target="_blank">
								<span className="p-2 mbr-iconfont socicon-whatsapp socicon" style={{ color: 'green', fill: '#104db7' }}></span>
							</a>
							<a className="iconfont-wrapper" href="https://www.instagram.com/softerinformatica/" target="_blank">
								<span className="p-2 mbr-iconfont socicon-instagram socicon" style={{ color: '#104db7', fill: '#104db7' }}></span>
							</a>
						</div>
					</div>
				</div>
			</nav>
		</section>
	);
};

export default Menu;
